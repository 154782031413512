import { observer } from "mobx-react";
import { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "utils/Routes";
import { injectStore, WithStoreProps } from "stores/RootStore";
// import Loading from "components/Loading";

const Container = styled.div``;

const Pages: FunctionComponent<WithStoreProps> = (props) => {
  return (
    <Container>
      {/* <Loading /> */}
      <Switch>
        {Object.values(Routes).map((route) => (
          <Route path={route.path} key={route.path} component={route.component} exact={route.exact} />
        ))}
      </Switch>
    </Container>
  );
};

export default injectStore(observer(Pages));
