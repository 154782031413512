import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";

export const RoutesEnum = {
  home: "/",
  privacyPolicy: "/privacy-policy",
}

export const Routes = {
  home: {
    component: Home,
    path: "/",
    exact: true,
  },
  privacyPolicy: {
    component: PrivacyPolicy,
    path: '/privacy-policy',
    exact: true,
  }
};
