import { useState, useCallback } from "react";
import styled from "styled-components";
import colors from "theme/colors";
import { ReactComponent as ArrowSvg } from "assets/images/arrow.svg";
import { processServerResponse } from "utils/httpUtils";
import { API_URL } from "config/Config";

const SignUpSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 90px 25px 250px 25px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  h2 {
    font-family: "Clash Display", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 46px;
    text-align: center;
    color: ${colors.text};
    margin-bottom: 65px;
  }

  .mobile {
    display: none;
  }

  h4 {
    width: 500px;
    max-width: 100%;
    font-family: "Clash Display", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: ${colors.textLight};
  }

  .error {
    margin-bottom: 20px;
    color: ${colors.neutralDanger};
  }

  .input-wrapper {
    width: 620px;
    max-width: 100%;
    position: relative;
    .input {
      width: 100%;
      height: 82px;
      background-color: rgba(255, 255, 255, 0.05);
      border: 3px solid rgba(255, 255, 255, 0.52);
      outline: none;
      padding: 0 195px 0 25px;
      color: #fff;
      font-family: "Clash Display", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      transition: all ease 0.3s;
      &:focus {
        border-color: #fff;
      }
    }
    .button {
      position: absolute;
      background-color: transparent;
      top: 0;
      right: 0;
      outline: none;
      height: 100%;
      width: 170px;
      border: none;
      border-left: 2px solid rgba(255, 255, 255, 0.5);
      overflow: hidden;
      border-top: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      transition: all ease 0.3s;
      svg {
        fill: #fff;
        fill-opacity: 0.5;
        transition: all ease 0.3s;
      }
      &:focus:not(:disabled) {
        border-color: #fff;
      }
      &:hover:not(:disabled) {
        border-color: #fff;
        svg {
          fill-opacity: 1;
          animation-name: move;
          animation-duration: 1000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletProtrait} {
    padding-top: 45px;
    padding-bottom: 20px;
    h2 {
      display: none;
    }
    .mobile {
      display: block;
      h2 {
        display: block;
        font-size: 26px;
        line-height: 40px;
        color: ${colors.text};
        margin-bottom: 10px;
      }
      p {
        font-family: "Clash Display";
        font-weight: 300;
        font-size: 19px;
        line-height: 40px;
        color: ${colors.textLight};
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    .input-wrapper {
      display: flex;
      flex-direction: column;
      .input {
        width: 100%;
        height: 60px;
        padding: 0 25px;
        border-width: 3px;
        font-size: 20px;
      }
      .button {
        width: 100%;
        height: 60px;
        position: relative;
        border: 3px solid rgba(255, 255, 255, 0.5);
        margin-top: -3px;
      }
    }
  }
`;

const SignUp: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [sending, setSending] = useState(false);

  const validateEmail = useCallback((value) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
  }, []);

  const validateForm = useCallback(
    (value) => {
      setError("");
      if (!value) {
        setError("Please enter email address.");
        return false;
      }

      if (!validateEmail(value)) {
        setError("Please enter a valid email address.");
        return false;
      }

      return true;
    },
    [validateEmail]
  );

  const onSignUp = useCallback(async () => {
    setIsFormDirty(true);
    if (!validateForm(email)) return;

    setSending(true);

    try {
      const response = await fetch(`${API_URL}/invstbl/mailing-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      await processServerResponse(response);
      setSignedUp(true);
    } catch (e) {
      console.error(e.detail);
      setError(e?.detail || "There was an error.");
    } finally {
      setSending(false);
    }
  }, [email, validateForm]);

  const onEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      validateForm(e.target.value);
    },
    [validateForm]
  );

  return (
    <SignUpSection className="container">
      {signedUp && (
        <>
          <h2>Thank You for Signing Up!</h2>
          <h4>You'll now be the first to know about updates and news</h4>
        </>
      )}

      {!signedUp && (
        <>
          <h2>
            Sign Up Now To Be The First To Know
            <br />
            When We Launch
          </h2>

          <div className="mobile">
            <h2>Sign up now!</h2>
            <p>Be the first to know when we launch.</p>
          </div>

          {isFormDirty && !!error && <div className="error">{error}</div>}

          <div className="input-wrapper">
            <input
              type="text"
              value={email}
              disabled={sending}
              className="input"
              placeholder="Your email"
              onChange={onEmailChange}
            />
            <button type="button" disabled={sending} className="button" onClick={onSignUp}>
              <ArrowSvg className="svg" />
            </button>
          </div>
        </>
      )}
    </SignUpSection>
  );
};

export default SignUp;
