import styled from "styled-components";

import colors from "theme/colors";
import RadialEffect from "./RadialEffect";

const LoadingDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};

  .effect-wrapper {
    width: 600px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    transform: translateY(160px);
    opacity: 0.6;
  }

  .logo {
    position: relative;
    z-index: 2;
    enable-background: new 0 0 595.3 841.9;
  }

  .st0 {
    fill: none;
  }
  .st1 {
    fill: #ffffff;
  }
  .st2 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .svg-elem-1 {
    animation: animate-svg-stroke-1 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-1 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }

  .svg-elem-2 {
    animation: animate-svg-stroke-2 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both,
      animate-svg-fill-2 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  .svg-elem-3 {
    animation: animate-svg-stroke-3 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both,
      animate-svg-fill-3 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }
  .svg-elem-4 {
    animation: animate-svg-stroke-4 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both,
      animate-svg-fill-4 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
  }
  .svg-elem-5 {
    animation: animate-svg-stroke-5 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both,
      animate-svg-fill-5 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
  }
  .svg-elem-6 {
    animation: animate-svg-stroke-6 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both,
      animate-svg-fill-6 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
  }
  .svg-elem-7 {
    animation: animate-svg-stroke-7 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.52s both,
      animate-svg-fill-7 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
  }
  .svg-elem-8 {
    animation: animate-svg-stroke-8 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both,
      animate-svg-fill-8 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
  }
  .svg-elem-10 {
    animation: animate-svg-stroke-10 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
      animate-svg-fill-10 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
  }
  .svg-elem-11 {
    animation: animate-svg-stroke-11 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both,
      animate-svg-fill-11 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
  }
  .svg-elem-12 {
    animation: animate-svg-stroke-12 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.85s both,
      animate-svg-fill-12 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
  }
  .svg-elem-13 {
    animation: animate-svg-stroke-13 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both,
      animate-svg-fill-13 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  }

  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 70.00069427490234px;
      stroke-dasharray: 70.00069427490234px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 70.00069427490234px;
    }
  }
  @keyframes fader {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 372px;
      stroke-dasharray: 372px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 372px;
    }
  }
  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 51.74755096435547px;
      stroke-dasharray: 51.74755096435547px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 51.74755096435547px;
    }
  }
  @keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-4 {
    0% {
      stroke-dashoffset: 69.77775573730469px;
      stroke-dasharray: 69.77775573730469px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.77775573730469px;
    }
  }
  @keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-5 {
    0% {
      stroke-dashoffset: 45px;
      stroke-dasharray: 45px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 45px;
    }
  }
  @keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-6 {
    0% {
      stroke-dashoffset: 75.88565826416016px;
      stroke-dasharray: 75.88565826416016px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 75.88565826416016px;
    }
  }
  @keyframes animate-svg-fill-6 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-7 {
    0% {
      stroke-dashoffset: 41px;
      stroke-dasharray: 41px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 41px;
    }
  }
  @keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-8 {
    0% {
      stroke-dashoffset: 61px;
      stroke-dasharray: 61px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 61px;
    }
  }
  @keyframes animate-svg-fill-8 {
    0% {
      fill: transparent;
    }
    100% {
      fill: rgb(255, 255, 255);
    }
  }
  @keyframes animate-svg-stroke-9 {
    0% {
      stroke-dashoffset: 354.9793701171875px;
      stroke-dasharray: 354.9793701171875px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 354.9793701171875px;
    }
  }
  .svg-elem-9 {
    animation: animate-svg-stroke-9 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
      animate-svg-fill-9 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
  }
  @keyframes animate-svg-stroke-10 {
    0% {
      stroke-dashoffset: 64.28968811035156px;
      stroke-dasharray: 64.28968811035156px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 64.28968811035156px;
    }
  }
  @keyframes animate-svg-stroke-11 {
    0% {
      stroke-dashoffset: 118.58232116699219px;
      stroke-dasharray: 118.58232116699219px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 118.58232116699219px;
    }
  }
  @keyframes animate-svg-stroke-12 {
    0% {
      stroke-dashoffset: 54.600006103515625px;
      stroke-dasharray: 54.600006103515625px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 54.600006103515625px;
    }
  }
  @keyframes animate-svg-stroke-13 {
    0% {
      stroke-dashoffset: 108.59999084472656px;
      stroke-dasharray: 108.59999084472656px;
    }
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 108.59999084472656px;
    }
  }
`;

const Loading: React.FC = () => {
  return (
    <LoadingDiv>
      <div className="effect-wrapper">
        <RadialEffect />
      </div>
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 595.3 841.9"
        className="logo"
        width="1190.60"
        height="1683.8"
      >
        <rect x="236" y="389.5" className="st0 svg-elem-1" width="246" height="62"></rect>
        <g>
          <path
            className="st1 svg-elem-2"
            d="M268.4,415.1h2.7l5.3,6c0.6,0.7,0.8,0.9,1.4,1.7c-0.1-1-0.1-1.3-0.1-2.2v-5.5h2.6v10.8h-2.7l-5.3-6.1
                c-0.6-0.7-0.8-0.9-1.4-1.7c0.1,0.8,0.1,1,0.1,1.8v5.9h-2.6L268.4,415.1L268.4,415.1z"
          ></path>
          <path
            className="st1 svg-elem-3"
            d="M281.1,415.1h3l2.9,7.1c0.2,0.4,0.2,0.6,0.3,1c0.1-0.5,0.2-0.6,0.4-1l2.9-7.1h2.9l-4.8,10.8H286L281.1,415.1
                L281.1,415.1z"
          ></path>
          <path
            className="st1 svg-elem-4"
            d="M295.4,422.5c0.6,1.3,2.2,1.7,3.6,1.7c0.7,0,2.7-0.1,2.7-1.4c0-0.8-0.7-1-1.3-1.1c-0.4-0.1-2.7-0.4-3.2-0.5
                c-1-0.2-3.5-0.7-3.5-3.1c0-0.5,0.2-1.1,0.4-1.5c0.9-1.5,2.9-1.8,4.6-1.8c1.6,0,2.8,0.2,3.9,0.8c1.1,0.6,1.6,1.4,1.8,1.7l-2.6,0.6
                c-0.1-0.2-0.3-0.6-0.9-0.9c-0.7-0.4-1.7-0.4-2.2-0.4c-1.2,0-2.4,0.2-2.4,1.3c0,0.7,0.6,0.9,1.4,1.1c0.4,0.1,2.6,0.5,3.1,0.6
                c1.3,0.2,3.4,0.7,3.4,3.1c0,3.5-4.1,3.6-5.5,3.6c-1.8,0-4.5-0.3-5.9-3L295.4,422.5L295.4,422.5z"
          ></path>
          <path className="st1 svg-elem-5" d="M305,415.1h10.7v2h-4v8.8H309V417h-4V415.1z"></path>
          <path
            className="st1 svg-elem-6"
            d="M317.2,415.1h7c1.1,0,2.5,0.1,3.2,1.1c0.4,0.5,0.4,1.1,0.4,1.5c0,0.3,0,0.9-0.4,1.4c-0.4,0.6-1,0.8-1.4,0.9
                c0.7,0.2,2.3,0.6,2.3,2.8c0,0.6-0.1,1.3-0.5,1.8c-0.8,1.3-2.2,1.3-3.3,1.3h-7.4L317.2,415.1L317.2,415.1z M319.9,419.3h3.9
                c0.3,0,0.6,0,0.9-0.1c0.6-0.3,0.6-1,0.6-1.1c0-1.1-0.9-1.2-1.3-1.2h-4.1L319.9,419.3L319.9,419.3z M319.9,423.9h4
                c0.6,0,1.8,0,1.8-1.5c0-1.3-1-1.3-1.4-1.3h-4.4V423.9z"
          ></path>
          <path className="st1 svg-elem-7" d="M330.2,415.1h2.7v8.8h6v2h-8.7V415.1L330.2,415.1z"></path>
          <path className="st1 svg-elem-8" d="M266.9,417v-2h-10.7v2h4v6.8h-4v2h4h2.7h4v-2h-4V417H266.9z"></path>
        </g>
        <polygon
          className="st2 svg-elem-9"
          points="244.5,390.8 358.2,390.8 358.2,442.8 350.5,450.4 236.8,450.4 236.8,398.6 "
        ></polygon>
        <polyline className="st2 svg-elem-10" points="237.6,450.4 244.3,443.4 244.3,390.8 "></polyline>
        <polyline className="st2 svg-elem-11" points="244.3,397.8 350.7,397.8 357.9,390.6 "></polyline>
        <line className="st2 svg-elem-12" x1="350.9" y1="450.4" x2="350.9" y2="397.8"></line>
        <line className="st2 svg-elem-13" x1="244.3" y1="443.4" x2="350.9" y2="443.4"></line>
      </svg>
    </LoadingDiv>
  );
};

export default Loading;
