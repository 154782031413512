import styled from "styled-components";
import { Link } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";

const FooterText = styled.p`
  font-family: "Poppins";
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 11px;
  line-height: 1;
  text-align: center;
  padding: 120px 0 65px 0;
  margin: 0;

  .link {
    display: inline-block;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    display: flex;
    flex-direction: column;
    .link {
      padding: 0;
      margin: 15px 0 0 0;
      border: none;
    }
  }
`;

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <FooterText>
      Copyright © {year} INVSTBLS Inc. d/b/a INVSTBL{" "}
      <Link to={RoutesEnum.privacyPolicy} className="link">
        Privacy Policy
      </Link>
    </FooterText>
  );
};

export default Footer;
