import { Footer, Header, SignUp } from "../components";
import Hero from "components/Hero";
import { useEffect } from "react";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <SignUp />
      <Footer />
    </>
  );
};

export default Home;
