import { FC } from "react";
import colors from "theme/colors";
import styled from "styled-components";

const EffectDiv = styled.div`
  border-radius: 50%;
  max-width: 100%;
  filter: blur(150px);
`;

interface RadialEffectProps {
  color?: string;
  width?: string;
  height?: string;
}

const RadialEffect: FC<RadialEffectProps> = ({ width, height, color }) => {
  return <EffectDiv style={{ width: width, height: height, backgroundColor: color }} />;
};

RadialEffect.defaultProps = {
  width: "100%",
  height: "100%",
  color: colors.primary,
};

export default RadialEffect;
