const colors = {
  primary: "#302c38",
  background: '#141414',
  text: '#D6D5D7',
  textLight: "#97959B",
  title: "#FFFFFF",
  
  secondary: "#FFFFFF",
  danger: "#D40000",
  neutralDanger: "#f92f60",
  success: "#00d26a",
  buttonColor: "#BFCCD3",
  inputBorder: "#BDCCD4",
  textDark: "#313B45",
  borderDark: "#212121",
  hoverLight: "#CCCCCC",
  hoverDark: "#1a1a1a",
}

export default colors