import styled from "styled-components";

import colors from "theme/colors";
import RadialEffect from "./RadialEffect";
import { ReactComponent as LogoSmallSvg } from "assets/images/logo-small.svg";
import { ReactComponent as CircleLogoSvg } from "assets/images/circle-logo.svg";
import CardOne from "assets/images/card-1.webp";
import CardTwo from "assets/images/card-2.webp";
import CardThree from "assets/images/card-3.webp";
import CardFour from "assets/images/card-4.webp";
import { useRef, useState, useEffect, useMemo } from "react";
import Loading from "./Loading";

const HeroSection = styled.section`
  overflow: hidden;
  background-color: ${colors.background};
  padding-top: 300px;
  overflow: hidden;

  .effect-wrapper {
    width: 900px;
    max-width: 100%;
    height: 900px;
    position: absolute;
    top: 300px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
    transform: translateY(160px);
    opacity: 0.6;
  }

  h1 {
    position: relative;
    z-index: 9;
    font-family: "Clash Display", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 121px;
    line-height: 130px;
    text-align: center;
    color: ${colors.title};
  }

  .cards {
    width: 100%;
    position: relative;
    padding-bottom: 55%;
    transition: all ease 0.3s;
    .item-card {
      position: absolute;
    }
    .card-1 {
      width: 41%;
      right: 100px;
      bottom: 200px;
      z-index: 4;
    }
    .card-2 {
      left: 200px;
      bottom: -25px;
      width: 48%;
      z-index: 1;
    }
    .card-3 {
      top: -60px;
      left: 0px;
      width: 45%;
      z-index: 2;
    }
    .card-4 {
      width: 34%;
      top: -110px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
    }
  }

  .footer {
    height: 100px;
    padding-bottom: 100px;
    width: 100%;
    background-color: ${colors.primary};
    .width {
      display: flex;
      justify-content: flex-end;
      .circle-logo {
        transform: translateY(-50%);
        width: 126px;
        height: 126px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.background};
        border-radius: 999px;
        z-index: 2;
        margin-right: 20px;
        .svg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        .spinning {
          animation-name: spin;
          animation-duration: 10000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  .width {
    width: 1240px;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
  }
  &.animate {
    .reveal {
      display: block;
      height: 130px;
      overflow: hidden;
      .reveal-content {
        display: block;
        transform: translateY(100%);
        animation: reveal 1.7s cubic-bezier(1, 0, 0.3, 0.9) forwards;
      }
      &:nth-child(2) .reveal-content {
        animation-delay: 0.6s;
      }
      &:nth-child(3) .reveal-content {
        animation-delay: 1.2s;
      }
    }

    .cards {
      animation: fadeIn 1600ms;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.tabletLandscape} {
    &.animate {
      .parallax-wrap {
        position: relative;
        .item-card {
          --shift-px: calc(var(--shift) * 1px);
          --size: 3vw;
          position: absolute;
          transition: all ease 0.1s;
          transform: translateX(calc((100vw - var(--x) * var(--shift-px)) / 100))
            translateY(calc((100vh - var(--y) * var(--shift-px)) / 100));

          &:nth-child(1) {
            --shift: -5;
          }

          &:nth-child(2) {
            --shift: 6;
          }

          &:nth-child(3) {
            --shift: 3;
          }

          &:nth-child(4) {
            --shift: -3;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    h1 {
      font-size: 100px;
      line-height: 110px;
    }
    &.animate {
      .reveal {
        height: 110px;
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    h1 {
      font-size: 80px;
      line-height: 90px;
    }
    &.animate {
      .reveal {
        height: 90px;
      }
    }
    .cards {
      .card-1 {
        right: 20px;
      }
      .card-3 {
        top: -10px;
      }
      .card-4 {
        top: -60px;
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletProtrait} {
    padding-top: 170px;
    h1 {
      font-size: 41px;
      line-height: 50px;
    }
    .effect-wrapper {
      width: 500px;
      height: 500px;
      top: 0;
    }
    .cards {
      padding-bottom: 47%;
      .card-1 {
        bottom: 90px;
        right: 0px;
      }
      .card-2 {
        left: 140px;
      }
      .card-3 {
        top: -30px;
        left: -20px;
      }
      .card-4 {
        top: -40px;
      }
    }
    .footer {
      .width {
        .circle-logo {
          transform: translateY(-30%);
        }
      }
    }
    &.animate {
      .reveal {
        height: 50px;
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    .footer {
      .width {
        .circle-logo {
          width: 83px;
          height: 83px;
          transform: translateY(-30%);
          .logo {
            width: 30px;
            height: 30px;
          }
          .spinning {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
    .cards {
      padding-bottom: 55%;
      .card-1 {
        width: 55%;
        bottom: 40px;
        right: -60px;
      }
      .card-2 {
        width: 60%;
        left: 45px;
        bottom: -25px;
      }
      .card-3 {
        width: 60%;
        top: -50px;
        left: -60px;
      }
      .card-4 {
        width: 40%;
      }
    }
  }
`;

const Hero: React.FC = () => {
  const [oneSecondDelay, setOneSecondDelay] = useState(false);
  const counter = useRef(0);

  const imageLoaded = () => {
    counter.current += 1;
  };

  useEffect(() => {
    setTimeout(() => setOneSecondDelay(true), 2300);

    const onMouseMove = ({ clientX, clientY }) => {
      document.documentElement.style.setProperty("--x", clientX);
      document.documentElement.style.setProperty("--y", clientY);
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, []);

  const loading = useMemo(() => {
    return !oneSecondDelay || counter.current < 4;
  }, [oneSecondDelay, counter]);

  return (
    <HeroSection className={!loading && "animate"}>
      {loading && <Loading />}

      <main className="width">
        <h1>
          <span className="reveal">
            <span className="reveal-content">Get Ready to</span>
            <br />
          </span>

          <span className="reveal">
            <span className="reveal-content">Change the Way</span>
            <br />
          </span>
          <span className="reveal">
            <span className="reveal-content">You Collect.</span>
          </span>
        </h1>

        <div className="effect-wrapper">
          <RadialEffect width="900px" height="900px" />
        </div>

        <div className="parallax-wrap">
          <div className="cards">
            <img src={CardOne} className="item-card card-1" alt="card" onLoad={imageLoaded} />
            <img src={CardTwo} className="item-card card-2" alt="card" onLoad={imageLoaded} />
            <img src={CardThree} className="item-card card-3" alt="card" onLoad={imageLoaded} />
            <img src={CardFour} className="item-card card-4" alt="card" onLoad={imageLoaded} />
          </div>
        </div>
      </main>

      <div className="footer">
        <div className="width">
          <div className="circle-logo">
            <LogoSmallSvg className="svg logo" />
            <CircleLogoSvg className="svg spinning" />
          </div>
        </div>
      </div>
    </HeroSection>
  );
};

export default Hero;
