import RadialEffect from "components/RadialEffect";
import { Footer, Header, Loading } from "../components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "theme/colors";
import { useState, useEffect } from "react";
import { RoutesEnum } from "utils/Routes";

const PrivacyPolicySection = styled.div`
  padding-top: 265px;
  .page-effect-wrapper {
    width: 1400px;
    max-width: 100%;
    height: 900px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
    transform: translateY(-15%);
  }
  .width {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
  }
  .content {
    width: 900px;
    max-width: 100%;
    padding: 0 25px;
    color: ${colors.textLight};
    position: relative;
    z-index: 2;
    h1 {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 47px;
      line-height: 58px;
      color: ${colors.title};
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-top: 40px;
        h2 {
          color: ${colors.title};
          font-family: "Clash Display";
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 37px;
          margin-bottom: 8px;
          padding-left: 5px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 29px;
          margin: 0;
        }
      }
    }
    .button {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      padding: 15px 20px;
      background-color: transparent;
      color: ${colors.title};
      margin-top: 80px;
      border: 2px solid rgba(255, 255, 255, 0.3);
    }
  }
  &.animate {
    .reveal {
      display: block;
      overflow: hidden;
      .reveal-content {
        display: block;
        transform: translateY(100%);
        animation: reveal 1.7s cubic-bezier(1, 0, 0.3, 0.9) forwards;
      }

      &:nth-child(2) .reveal-content {
        animation-delay: 0.4s;
      }

      &:nth-child(3) .reveal-content {
        animation-delay: 0.8s;
      }

      &:nth-child(4) .reveal-content {
        animation-delay: 1.2s;
      }

      &:nth-child(5) .reveal-content {
        animation-delay: 1.6s;
      }
      &:nth-child(6) .reveal-content {
        animation-delay: 2s;
      }
      &:nth-child(7) .reveal-content {
        animation-delay: 2.4s;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletProtrait} {
    padding-top: 140px;
    .content {
      h1 {
        font-size: 27px;
        line-height: 33px;
      }
      ul {
        li {
          margin-top: 30px;
          h2 {
            font-size: 19px;
            line-height: 23px;
            margin-bottom: 8px;
          }
          p {
            font-size: 12px;
            line-height: 21px;
          }
        }
      }
    }
  }
`;

const PrivacyPolicy: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <PrivacyPolicySection className={!loading && "animate"}>
      {loading && <Loading />}

      <div className="page-effect-wrapper">
        <RadialEffect width="1400px" height="300px" color="rgba(0, 0, 0, 0.5)" />
      </div>

      <Header privacyPolicyPage />

      <div className="width">
        <div className="content">
          <h1 className="reveal">
            <span className="reveal-content">Privacy Policy</span>
          </h1>

          <ul>
            <li className="reveal">
              <div className="reveal-content">
                <h2>1. Collection of Information</h2>
                <p>
                  We respect your privacy and are committed to protecting it through compliance with this privacy policy
                  (this “Privacy Policy”). We collect personal information, such as your name and email address, when
                  you register with our website or submit a contact form. We also collect non-personal information such
                  as your browser type, IP address and operating system for analytics purposes.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>2. Use of Information</h2>
                <p>
                  We use your personal information to provide you with our services, respond to your inquiries and to
                  communicate with you (including sending you marketing and promotional offers). We also use
                  non-personal information to improve our website and enhance user experience.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>3. Sharing of Information</h2>
                <p>
                  We may disclose your personal information that we collect or you provide with our subsidiaries,
                  affiliates, contractors, service providers and other third parties we use to support our business. We
                  may disclose your personal information to fulfill the purpose for which you provide it and for any
                  other purpose disclosed by us when you provide the information. We otherwise do not share your
                  personal information with third parties without your consent, except in cases where we are required by
                  law or to protect our rights and interests. We may share non-personal information with third parties
                  for marketing or analytics purposes. We have no control over the privacy practices of any third party
                  we may share your personal information with your consent or your non-personal information with for
                  marketing and analytics purposes. As such, we are not responsible for the privacy policies of any such
                  third party.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>4. Your Rights and Choices Regarding Information</h2>
                <p>
                  You may have certain rights regarding your personal information, including rights to access, update,
                  correct, limit or delete your personal information. We will review your data requests on a
                  case-by-case basis in accordance with applicable law.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>5. Marketing Communications</h2>
                <p>
                  If you do not wish to have your email address used by us, you send us an email stating your request at
                  socials@INVSTBL.com. If we have sent you a marketing or promotional email, you may follow the
                  instructions in that email on how to be omitted from future email distributions.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>6. Cookies</h2>
                <p>
                  We use cookies to enhance your user experience and to track website usage. Cookies are small textile
                  files stored on your device when accessing most websites on the internet or to open certain emails.
                  Cookies enable a website to remember information about you to offer you an improved and more
                  personalized function. You can choose to disable cookies in your browser settings, but this may affect
                  the functionality of our website.
                </p>
              </div>
            </li>
            <li className="reveal">
              <div className="reveal-content">
                <h2>7. Changes to Our Privacy Policy</h2>
                <p>
                  We reserve the right to change this Privacy Policy from time to time in our sole discretion. By
                  accessing this website after we make any such changes to our Privacy Policy, you are deemed to have
                  accepted such changes. When required under applicable law, we will seek affirmative consent from you
                  before making material changes to how we handle data previously collected from you. If you do not
                  provide such consent, we will continue to use your information in a manner consistent with the Privacy
                  Policy under which it was collected, or the information will be deleted.
                </p>
              </div>
            </li>
          </ul>

          <Link to={RoutesEnum.home}>
            <button className="button">Take me home</button>
          </Link>
        </div>
      </div>

      <Footer />
    </PrivacyPolicySection>
  );
};

export default PrivacyPolicy;
