import styled from "styled-components";
import { useEffect, useState } from "react";
import { ReactComponent as LogoSvg } from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import clsx from "clsx";
import colors from "theme/colors";
import { RoutesEnum } from "utils/Routes";

const HeaderContainer = styled.header`
  width: 100%;
  max-width: 1325px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 80px 25px 0 25px;
  .logo {
    cursor: pointer;
  }

  &.with-links {
    justify-content: space-between;
  }

  .only-on-mobile {
    display: none;
  }

  .link {
    color: ${colors.title};
    font-family: "Clash Display";
    font-weight: 500;
    font-size: 18px;
    padding: 5px 10px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletProtrait} {
    padding-top: 30px;
    justify-content: space-between;
    .link {
      font-weight: 500;
      font-size: 18px;
    }
    .only-on-mobile {
      display: block;
    }
  }
`;

interface HeaderProps {
  privacyPolicyPage?: boolean;
}

const Header: React.FC<HeaderProps> = ({ privacyPolicyPage }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const onResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <HeaderContainer className={clsx("header-section", privacyPolicyPage && "with-links")}>
      <Link to={RoutesEnum.home}>
        <span className="logo">
          <LogoSvg width={width < 768 ? "100px" : "123px"} />
        </span>
      </Link>

      {privacyPolicyPage && (
        <Link to={RoutesEnum.home}>
          <div className="link">Home</div>
        </Link>
      )}
      {!privacyPolicyPage && (
        <Link to={RoutesEnum.privacyPolicy}>
          <div className="link only-on-mobile">Privacy Policy</div>
        </Link>
      )}
    </HeaderContainer>
  );
};

Header.defaultProps = {
  privacyPolicyPage: false,
};

export default Header;
