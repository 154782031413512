import { ThemeProvider } from "styled-components/macro";
import colors from "./colors";

interface ThemeProps {
  children: JSX.Element;
}

export interface ITheme {
  colors: {
    primary: string;
    secondary: string;
    danger: string;
    neutralDanger: string;
    background: string;
    buttonColor: string;
    text: string;
    inputBorder: string;
    textLight: string;
    textDark: string;
    hoverLight: string;
    borderDark: string;
    hoverDark: string;
    success: string;
  };
  mediaQueries: {
    phone: string;
    tabletProtrait: string;
    tabletLandscape: string;
    desktop: string;

    bellowPhone: string;
    bellowTabletProtrait: string;
    bellowTabletLandscape: string;
    bellowDesktop: string;
  };
}

const theme: ITheme = {
  colors,
  mediaQueries: {
    phone: "only screen and (min-width: 576px)",
    tabletProtrait: "only screen and (min-width: 768px)",
    tabletLandscape: "only screen and (min-width: 1024px)",
    desktop: "only screen and (min-width: 1280px)",
    bellowPhone: "only screen and (max-width: 575.98px)",
    bellowTabletProtrait: "only screen and (max-width: 767.98px)",
    bellowTabletLandscape: "only screen and (max-width: 1023.98px)",
    bellowDesktop: "only screen and (max-width: 1279.98px)",
  },
};

const Theme = ({ children }: ThemeProps): JSX.Element => {
  return <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>;
};

export default Theme;
